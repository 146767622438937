import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "new-students"
    }}>{`New Students`}</h3>
    <ul>
      <li parentName="ul">{`Please `}<Link to="/contact" mdxType="Link">{`contact us`}</Link>{` to conirm your first class.`}</li>
      <li parentName="ul">{`Note that we are on the second floor, and you must climb stairs.`}</li>
      <li parentName="ul">{`Please consult your physician before starting this or any exercise program.`}</li>
    </ul>
    <h3 {...{
      "id": "dress-code"
    }}>{`Dress Code`}</h3>
    <ul>
      <li parentName="ul">{`Please wear loose and comfortable clothing.`}</li>
      <li parentName="ul">{`Please `}<strong parentName="li">{`Do Not`}</strong>{` Wear: shorts, tank tops, spandex, tights, jewelry, or fragrance.`}</li>
      <li parentName="ul">{`Wear socks or school slippers - no outside shoes on the classroom floor.`}</li>
    </ul>
    <h3 {...{
      "id": "weather"
    }}>{`Weather`}</h3>
    <p>{`We will cancel classes when public schools are closed for inclement weather, when snow accumulation is 3 inches or more, or when icy conditions exist. Please check the announcements at the top of the `}<Link to="/" mdxType="Link">{`home page`}</Link>{` when in doubt.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      